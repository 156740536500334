<script>
import { defineComponent } from 'vue'
import DefaultDrawerAction from '/~/components/drawer/components/actions/drawer-action.vue'

export default defineComponent({
  name: 'drawer-action-v3',
  extends: DefaultDrawerAction,
})
</script>

<template>
  <div
    v-clickaway="onClickaway"
    class="relative w-full overflow-hidden rounded-md p-[5px]"
  >
    <button
      class="w-full cursor-pointer rounded-xl border bg-white p-[15px] text-left hover:opacity-90 disabled:cursor-not-allowed disabled:border-disabled disabled:bg-transparent disabled:opacity-50"
      :disabled="isDisabled"
      @click="onClick"
    >
      <span class="flex items-center justify-between">
        <span
          class="mr-[15px] flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full"
          :class="[action.iconColor ? action.iconColor : 'text-primary']"
        >
          <base-icon :svg="action.icon" :size="action.size || 40" />
        </span>
        <span class="block min-w-0 flex-grow leading-6 text-eonx-neutral-800">
          <span class="block truncate font-bold">
            {{ action.label }}
          </span>
          <span class="block overflow-hidden truncate break-words">
            {{ action.description }}
          </span>
        </span>
        <base-icon svg="heroicons/solid/chevron-right" :size="24" />
      </span>
    </button>
    <transition
      enter-class="translate-x-full"
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      leave-to-class="translate-x-full"
    >
      <div
        v-if="isConfirmVisible"
        class="absolute inset-0 flex h-full w-full transform items-center justify-between rounded-md transition duration-100 ease-out"
        :class="typeClass"
      >
        <span class="px-5 font-bold">
          {{ confirmText }}
        </span>
        <div
          v-if="!isProcessing"
          class="flex flex-shrink-0 items-center space-x-2.5 pr-[15px]"
        >
          <button
            v-for="confirmAction in confirmActions"
            :key="confirmAction.label"
            class="flex h-10 w-10 items-center justify-center rounded-full bg-white"
            :class="[
              confirmAction.isSubmit ? 'text-fg-success' : 'text-fg-error',
            ]"
            @click="onConfirmAction(confirmAction)"
          >
            <base-icon :svg="confirmAction.icon" size="md" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
